import { FC, PropsWithChildren, ReactElement } from 'react';

import { QueryClientProvider } from '@tanstack/react-query';

import { queryClient } from '@shared/clients/http/ReactQuery';
import { FeatureFlagProvider } from '@shared/components/FeatureFlag/providers/FeatureFlagProvider';

import { AppAuthProvider } from '@modules/auth/contexts';

import { AppBalancesProvider } from '@modules/balances/contexts';

import { AppProvider } from '@modules/globals/contexts/AppContext';
import { LoaderProvider } from '@modules/globals/contexts/LoaderContext';
import { ThemeProvider } from '@modules/globals/contexts/ThemeContext';

import { AppHorsesProvider } from '@modules/horses/contexts';

import { AppInvoicesProvider } from '@modules/invoices/contexts';

import { AppLicensesProvider } from '@modules/licenses/contexts';

import { AppNotificationsProvider } from '@modules/notifications/contexts';

import { AppOwnersProvider } from '@modules/owners/contexts';

import { AppPaymentsProvider } from '@modules/payments/contexts';

import { AppProfileProvider } from '@modules/profiles/contexts';

import { AppRidersProvider } from '@modules/riders/contexts';

import { AppSubscriptionsProvider } from '@modules/subscriptions/context';

import { AppTeachersProvider } from '@modules/teachers/contexts';

import { AppWarehouseProvider } from '@modules/warehouse/contexts';

export const EquitesAppProvider: FC<PropsWithChildren> = ({ children }): ReactElement => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <LoaderProvider>
          <AppProvider>
            <AppProfileProvider>
              <FeatureFlagProvider>
                <AppAuthProvider>
                  <AppSubscriptionsProvider>
                    <AppPaymentsProvider>
                      <AppRidersProvider>
                        <AppTeachersProvider>
                          <AppWarehouseProvider>
                            <AppHorsesProvider>
                              <AppOwnersProvider>
                                <AppBalancesProvider>
                                  <AppLicensesProvider>
                                    <AppNotificationsProvider>
                                      <AppInvoicesProvider>{children}</AppInvoicesProvider>
                                    </AppNotificationsProvider>
                                  </AppLicensesProvider>
                                </AppBalancesProvider>
                              </AppOwnersProvider>
                            </AppHorsesProvider>
                          </AppWarehouseProvider>
                        </AppTeachersProvider>
                      </AppRidersProvider>
                    </AppPaymentsProvider>
                  </AppSubscriptionsProvider>
                </AppAuthProvider>
              </FeatureFlagProvider>
            </AppProfileProvider>
          </AppProvider>
        </LoaderProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};
