import { AxiosInstance, AxiosResponse } from 'axios';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import { IHorseOdontology } from '@modules/horses/types/Odontology/odontology';
import {
  ICreateHorseOdontologyRequest,
  ICreateHorseOdontologyResponse,
  IDeleteHorseOdontologistReportRequest,
  IDeleteHorseOdontologyRequest,
  IFindHorseOdontologyByHorseIdRequest,
  IUpdateHorseOdontologyRequest,
  IUpdateHorseOdontologyResponse,
  IUploadHorseOdontologistReportRequest,
  IUploadHorseOdontologistReportResponse,
} from '@modules/horses/types/Odontology/requests';

type IGetHorseOdontologyResponse = Promise<AxiosResponse<IPaginateDTO<IHorseOdontology>>>;
type IUploadOdontologistReportResponse = Promise<AxiosResponse<IUploadHorseOdontologistReportResponse>>;

export class HorseOdontology {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  createOdontology(data: ICreateHorseOdontologyRequest): Promise<AxiosResponse<ICreateHorseOdontologyResponse>> {
    const { horseId, ...body } = data;
    return this.instance.post(`/horses/${horseId}/odontology`, body);
  }

  deleteOdontology(data: IDeleteHorseOdontologyRequest): Promise<AxiosResponse<void>> {
    const { horseId, odontologyId } = data;
    return this.instance.delete(`/horses/${horseId}/odontology/${odontologyId}`);
  }

  getHorseOdontology(data: IFindHorseOdontologyByHorseIdRequest): IGetHorseOdontologyResponse {
    const { horseId, ...params } = data;
    return this.instance.get(`/horses/${horseId}/odontology`, { params });
  }

  updateOdontology(data: IUpdateHorseOdontologyRequest): Promise<AxiosResponse<IUpdateHorseOdontologyResponse>> {
    const { horseId, odontologyId, ...body } = data;
    return this.instance.patch(`/horses/${horseId}/odontology/${odontologyId}`, body);
  }

  // ---------- Reports ----------

  updateOdontologyReport(data: IUploadHorseOdontologistReportRequest): IUploadOdontologistReportResponse {
    const { horseId, odontologyId, file } = data;

    const formData = new FormData();
    formData.append('report', file);

    return this.instance.patch(`/horses/${horseId}/odontology/${odontologyId}/report`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  deleteOdontologyReport(data: IDeleteHorseOdontologistReportRequest): Promise<AxiosResponse<void>> {
    const { horseId, odontologyId } = data;
    return this.instance.delete(`/horses/${horseId}/odontology/${odontologyId}/report`);
  }
}

export const horseOdontology = (instance: AxiosInstance): HorseOdontology => new HorseOdontology(instance);
