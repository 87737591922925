/**
 * ConfigurationRoles
 * @readonly
 * @enum {string}
 */
export enum ConfigurationRoles {
  /** Create a new pavilion */
  CREATE_CONFIGURATION_HORSE_PAVILION = 'CREATE_CONFIGURATION_HORSE_PAVILION',

  /** Create a new stall */
  CREATE_CONFIGURATION_HORSE_STALL = 'CREATE_CONFIGURATION_HORSE_STALL',

  /** Delete a pavilion */
  DELETE_CONFIGURATION_HORSE_PAVILION = 'DELETE_CONFIGURATION_HORSE_PAVILION',

  /** Delete a stall */
  DELETE_CONFIGURATION_HORSE_STALL = 'DELETE_CONFIGURATION_HORSE_STALL',

  /** Get configuration from company */
  GET_CONFIGURATIONS = 'GET_CONFIGURATIONS',

  /** Get pavilions */
  GET_CONFIGURATIONS_HORSES_PAVILIONS = 'GET_CONFIGURATIONS_HORSES_PAVILIONS',

  /** Get stalls */
  GET_CONFIGURATIONS_HORSES_STALLS = 'GET_CONFIGURATIONS_HORSES_STALLS',

  /** Update image to show in reports */
  UPDATE_CONFIGURATION_IMAGE_REPORTS = 'UPDATE_CONFIGURATION_IMAGE_REPORTS',

  /** Update general configurations from company */
  UPDATE_CONFIGURATIONS = 'UPDATE_CONFIGURATIONS',

  /** Update configurations notifications from company */
  UPDATE_CONFIGURATIONS_NOTIFICATIONS = 'UPDATE_CONFIGURATIONS_NOTIFICATIONS',

  /** Update a pavilion */
  UPDATE_CONFIGURATION_HORSE_PAVILION = 'UPDATE_CONFIGURATION_HORSE_PAVILION',

  /** Update a stall */
  UPDATE_CONFIGURATION_HORSE_STALL = 'UPDATE_CONFIGURATION_HORSE_STALL',
}
