import { AxiosInstance } from 'axios';

import { CurrentCustomer } from '@modules/payments/types/Customers/customers';
import { UpdateCustomerRequest } from '@modules/payments/types/Customers/requests';

export class Customers {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Customers ----------

  async createCurrentProfile(): Promise<void> {
    await this.instance.post('/customers/me', { origin: 'COMPANY' });
  }

  async getCurrentCustomer(): Promise<CurrentCustomer> {
    const response = await this.instance.get('/customers/me');

    return response.data;
  }

  async updateCurrentProfile(data: UpdateCustomerRequest): Promise<void> {
    await this.instance.put('/customers/me', data);
  }

  async deleteCurrentBankAccount(): Promise<void> {
    await this.instance.delete('/customers/me/bank-account');
  }
}

export const customers = (instance: AxiosInstance): Customers => new Customers(instance);
