import { AxiosInstance } from 'axios';

import { Bank } from '@modules/banks/types/Banks/banks';

export class Banks {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Banks ----------

  async getAll(): Promise<Bank[]> {
    const response = await this.instance.get('/banks');

    return response.data;
  }
}

export const banks = (instance: AxiosInstance): Banks => new Banks(instance);
