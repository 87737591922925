/**
 * HorseRoles
 * @readonly
 * @enum {string}
 */
export enum HorseRoles {
  /** Create a new horse. */
  CREATE_HORSE = 'CREATE_HORSE',

  /** Create horse exams. */
  CREATE_HORSE_EXAMS = 'CREATE_HORSE_EXAMS',

  /** Create a new horse expense. */
  CREATE_HORSE_EXPENSES = 'CREATE_HORSE_EXPENSES',

  /** Create a new feed to horses. */
  CREATE_HORSE_FEED = 'CREATE_HORSE_FEED',

  /** Add a new food to feed one horse. */
  CREATE_HORSE_FOOD = 'CREATE_HORSE_FOOD',

  /** Create a new horseshoes register to one horse. */
  CREATE_HORSE_HORSESHOES = 'CREATE_HORSE_HORSESHOES',

  /** Create horse medicine application. */
  CREATE_HORSE_MEDICINES = 'CREATE_HORSE_MEDICINES',

  /** Create horse odontologist. */
  CREATE_HORSE_ODONTOLOGIST = 'CREATE_HORSE_ODONTOLOGIST',

  /** Create horse vaccines. */
  CREATE_HORSE_VACCINES = 'CREATE_HORSE_VACCINES',

  /** Create a new owner to horses. */
  CREATE_OWNER = 'CREATE_OWNER',

  /** Delete a horse. */
  DELETE_HORSE = 'DELETE_HORSE',

  /** Delete a horse exam. */
  DELETE_HORSE_EXAMS = 'DELETE_HORSE_EXAMS',

  /** Delete a horse expense. */
  DELETE_HORSE_EXPENSE = 'DELETE_HORSE_EXPENSE',

  /** Delete a horse feed. */
  DELETE_HORSE_FEED = 'DELETE_HORSE_FEED',

  /** Delete a horse food. */
  DELETE_HORSE_FOOD = 'DELETE_HORSE_FOOD',

  /** Delete a horseshoe from a horse. */
  DELETE_HORSE_HORSESHOES = 'DELETE_HORSE_HORSESHOES',

  /** Delete a horse medicine application. */
  DELETE_HORSE_MEDICINES = 'DELETE_HORSE_MEDICINES',

  /** Delete a horse odontologist. */
  DELETE_HORSE_ODONTOLOGIST = 'DELETE_HORSE_ODONTOLOGIST',

  /** Delete a horse owner. */
  DELETE_OWNER = 'DELETE_OWNER',

  /** Delete a horse exam report. */
  DELETE_HORSE_EXAM_REPORT = 'DELETE_HORSE_EXAM_REPORT',

  /** Delete a horse odontologist  report. */
  DELETE_HORSE_ODONTOLOGIST_REPORT = 'DELETE_HORSE_ODONTOLOGIST_REPORT',

  /** Delete horse vaccines. */
  DELETE_HORSE_VACCINES = 'DELETE_HORSE_VACCINES',

  /** Export horses expenses as PDF. */
  EXPORT_HORSE_EXPENSES = 'EXPORT_HORSE_EXPENSES',

  /** Get a horse exames. */
  GET_HORSE_EXAMS = 'GET_HORSE_EXAMS',

  /** Get a horse expenses. */
  GET_HORSE_EXPENSES = 'GET_HORSE_EXPENSES',

  /** Get a current feed to one horse. */
  GET_HORSE_FEEDS = 'GET_HORSE_FEEDS',

  /** Get horses feeds detailed per food. */
  GET_HORSE_FEEDS_DETAILED = 'GET_HORSE_FEEDS_DETAILED',

  /** Get a horse feeds history. */
  GET_HORSE_FEEDS_HISTORY = 'GET_HORSE_FEEDS_HISTORY',

  /** Get a horseshoes to one horse */
  GET_HORSE_HORSESHOES = 'GET_HORSE_HORSESHOES',

  /** Get a horse medicines applications. */
  GET_HORSE_MEDICINES = 'GET_HORSE_MEDICINES',

  /** Get a horse odontologist. */
  GET_HORSE_ODONTOLOGIST = 'GET_HORSE_ODONTOLOGIST',

  /** Get a horse profile. */
  GET_HORSE_PROFILE = 'GET_HORSE_PROFILE',

  /** Get horses summary list */
  GET_HORSES = 'GET_HORSES',

  /** Get a horse owners. */
  GET_HORSE_OWNERS = 'GET_HORSE_OWNERS',

  /** Get a horse vaccines. */
  GET_HORSE_VACCINES = 'GET_HORSE_VACCINES',

  /** Update a horse. */
  UPDATE_HORSE = 'UPDATE_HORSE',

  /** Update a horse photo. */
  UPDATE_HORSE_AVATAR = 'UPDATE_HORSE_AVATAR',

  /** Update a horse exam. */
  UPDATE_HORSE_EXAMS = 'UPDATE_HORSE_EXAMS',

  /** Update a horse expense. */
  UPDATE_HORSE_EXPENSES = 'UPDATE_HORSE_EXPENSES',

  /** Update a horse feed, add comments. */
  UPDATE_HORSE_FEED = 'UPDATE_HORSE_FEED',

  /** Update a horse food. */
  UPDATE_HORSE_FOOD = 'UPDATE_HORSE_FOOD',

  /** Update a horse medicine application. */
  UPDATE_HORSE_MEDICINES = 'UPDATE_HORSE_MEDICINES',

  /** Update a horse odontologist. */
  UPDATE_HORSE_ODONTOLOGIST = 'UPDATE_HORSE_ODONTOLOGIST',

  /** Update an owner. */
  UPDATE_OWNER = 'UPDATE_OWNER',

  /** Update a horse owner. */
  UPDATE_HORSE_OWNERS = 'UPDATE_HORSE_OWNERS',

  /** Upload a horse exam report. */
  UPLOAD_HORSE_EXAM_REPORT = 'UPLOAD_HORSE_EXAM_REPORT',

  /** Upload a horse odontologist report. */
  UPLOAD_HORSE_ODONTOLOGIST_REPORT = 'UPLOAD_HORSE_ODONTOLOGIST_REPORT',
}
