import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createContext } from 'use-context-selector';

import { PaymentsClient } from '@shared/clients/http/PaymentsClient';
import { toast } from '@shared/components/Toast';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { ICreditCardContext } from '@modules/payments/types/CreditCards/context';
import { ICreditCard } from '@modules/payments/types/CreditCards/creditCards';
import { ICreateCreditCardRequest, IDeleteCreditCardRequest } from '@modules/payments/types/CreditCards/requests';

const CreditCardsContext = createContext({} as ICreditCardContext);
CreditCardsContext.displayName = 'CreditCards';

const CreditCardsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('payments', { keyPrefix: 'messages' });

  const { startLoad, endLoad } = useLoader();

  const [creditCards, setCreditCards] = useState<ICreditCard[]>([]);

  const findCreditCards = useCallback(async () => {
    try {
      startLoad();

      const response = await PaymentsClient.creditCards().getCreditCards();

      setCreditCards(response.data);
    } catch (err) {
      HandleApiErrors.handle({ err });
    } finally {
      endLoad();
    }
  }, [endLoad, startLoad]);

  const createCreditCard = useCallback(
    async (data: ICreateCreditCardRequest) => {
      try {
        startLoad();

        await PaymentsClient.customers().createCurrentProfile();

        await PaymentsClient.creditCards().createCreditCard({ token: data.token });

        await findCreditCards();

        toast(t('credit_card_created_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, findCreditCards, startLoad, t],
  );

  const deleteCreditCard = useCallback(
    async (data: IDeleteCreditCardRequest) => {
      try {
        startLoad();

        await PaymentsClient.creditCards().deleteCreditCard(data);

        await findCreditCards();

        toast(t('credit_card_deleted_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, findCreditCards, startLoad, t],
  );

  const contextValue = useMemo<ICreditCardContext>(
    () => ({ createCreditCard, creditCards, deleteCreditCard, findCreditCards }),
    [createCreditCard, creditCards, deleteCreditCard, findCreditCards],
  );

  return <CreditCardsContext.Provider value={contextValue}>{children}</CreditCardsContext.Provider>;
};

export { CreditCardsContext, CreditCardsProvider };
